import { createContext, useState } from "react";
import moment from "moment";
import useFormatedDate from "../hooks/useFormatedDate";
import { useLocation } from "react-router-dom";

export const BookingsContext = createContext<any>(null);
BookingsContext.displayName = "bookingsContext";

interface DynamicModalData {
  isDynamic: boolean;
  propertyId: number;
  propertyName: {
    value: string;
    label: string;
  };
  from: {
    year: number;
    month: number;
    day: number;
  };
  to: {
    year: number;
    month: number;
    day: number;
  };
}

interface SelectedProperty {
  id: number | undefined | null;
  name: string | undefined | null;
}

// To format recived data
export const formatData = (data: any[], sourcePage?: string) => {
  const formatedData = data.map((d) => {
    const [arrivalYear, arrivalMonth, arrivalDay] = d.arrival_date?.split("-");
    const [departureYear, departureMonth, departureDay] =
      d.departure_date?.split("-");

    const arrival = moment(
      [+arrivalYear, +arrivalMonth, +arrivalDay],
      "YYYY/MM/DD"
    );

    const departure = moment(
      [+departureYear, +departureMonth, +departureDay],
      "YYYY/MM/DD"
    );

    const { getToday } = useFormatedDate();

    const pendingDays = moment(getToday(), "YYYY/MM/DD").diff(
      d.date_confirmed?.split(" ")[0],
      "days"
    );

    return {
      ...d,
      manager_full_name: `${d.manager_name} ${d.manager_surname || ""}`,
      // owner_full_name: `${d.salesperson_name} ${d.salesperson_surname || ""}`,
      booking_amount: d.booking_amount
        ? parseFloat(d.booking_amount).toLocaleString("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits:
              String(d.booking_amount).length === 1 ? 0 : 2,
          })
        : 0,
      deleted_at: d.deleted_at || "",
      booking_ref: d.booking_ref || "",
      hours: d.hours || "",
      so_number: d.so_number || "",
      no_guests: d.no_guests || "",
      guest_id_no: d.guest_id_no || "",
      arrival_time: d.arrival_time || "",
      departure_time: d.departure_time || "",
      issue_id: d.issue_id || "",
      manager_surname: d.manager_surname || "",
      nights: departure.diff(arrival, "days"),
      status: [d.status, d.status ? 2 : d.quote_confirmed],
      // status:
      //  sourcePage === "overlapping-bookings"
      //    ? 0
      //    : [d.status, d.status ? 2 : d.quote_confirmed],
      pending_days: d.quote_confirmed ? "NA" : pendingDays,
      date_confirmed: d.date_confirmed ? d.date_confirmed?.split(" ")[0] : "NA",
    };
  });

  return formatedData;
};

export default function Bookings({ children }: any) {
  const location = useLocation();
  if (sessionStorage.hasOwnProperty("selected_property_id")) {
    const parts = location.pathname.split("/");
    if (parts.length > 1 && parts[1] !== "establishment-list") {
      sessionStorage.removeItem("selected_property_id");
      sessionStorage.removeItem("property_name");
    }
  }
  // data to be shared
  const [contextData, setContextData] = useState<any>([{}]);
  const [selectedProperty, setSelectedProperty] = useState<SelectedProperty>({
    id: null,
    name: null,
  });

  // data to be shared
  const [allProperties, setAllProperties] = useState<any>([{}]);

  // saves user's details
  const [userDetails, setUserDetails] = useState<any>([{}]);

  // holds the message in case there's an error
  const [apiFail, setApiFail] = useState<string>("");

  // loading state
  const [loading, setLoading] = useState<boolean>(false);

  // Property managers array
  const [propertyManagers, setPropertyManagers] = useState<
    { label: string; value: number }[]
  >([]);

  // calendar create booking modal data
  const [dynamicModalData, setDynamicModalData] = useState<DynamicModalData>();

  //=======Benito
  const [loggedInUser, setLoggedInUser] = useState<any>([{}]);

  const handleSetLoggedInUser = (user: any) => {
    setLoggedInUser([user]);
  };
  //=========

  // API url

  // const BASE_URL = "https://test.airagents.co.za";
  // Test API URL
  const BASE_URL = "https://www.test.hostagents.co.za";
  // Live API URL
  // const BASE_URL = "https://www.hostagents.co.za";
  // const BASE_URL = "https://localhost/test_airagaents";

  // API Version
  const API_VERSION = "/api/v2";

  // API key
  const API_KEY = "31e64424a3d86408c4c062abcb698571";
  {
    /* OLD WATI API key
  const WATI_API_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNWMzMzU5Yy00NGY4LTQxMWItYjcyZS0xNmNlYTAyOGE4NzYiLCJ1bmlxdWVfbmFtZSI6InJvbmVsQGhvc3RhZ2VudHMuY29tIiwibmFtZWlkIjoicm9uZWxAaG9zdGFnZW50cy5jb20iLCJlbWFpbCI6InJvbmVsQGhvc3RhZ2VudHMuY29tIiwiYXV0aF90aW1lIjoiMDQvMjMvMjAyNCAxNDoyNDozNyIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIxMDQ2NzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.7MdJeVJFBTFV2SBxkwn-SNn4C9iou1O8Dvtme3JtxBw";
  */
  }
  const WATI_API_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYmU4MWJjYi02NTExLTRjYTQtOWUyZS0wMzk2OGVhOWI1ZWYiLCJ1bmlxdWVfbmFtZSI6InNpbW9uZUBob3N0YWdlbnRzLmNvLnphIiwibmFtZWlkIjoic2ltb25lQGhvc3RhZ2VudHMuY28uemEiLCJlbWFpbCI6InNpbW9uZUBob3N0YWdlbnRzLmNvLnphIiwiYXV0aF90aW1lIjoiMDkvMDIvMjAyNCAxMzo1NzoyNCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIxMDQ2NzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.Xe82ujzXQw4gZYWCLfXv8fvzblYmBMdrQngm6RRlCqE";
  // OLD WATI base URL is commented out below as they changed the structure of it
  // const WATI_BASE_URL = "https://live-server-104676.wati.io/api/v1";
  // NEW WATI base URKL is below as they changed the structure of
  const WATI_BASE_URL = "https://live-mt-server.wati.io/104676/api/v1";

  // Get API data
  const getData = async (
    id: number,
    noBookings?: boolean,
    month?: number,
    year?: number,
    activateLoading: boolean = true,
    quoted: boolean = false,
    overlaping: boolean = false
  ) => {
    try {
      if (activateLoading) setLoading(true);
      const { groupId } = JSON.parse(sessionStorage.getItem("ubold_user")!);

      if (noBookings) {
        const reponses = await fetch(
          `${BASE_URL}${API_VERSION}/properties/${id}`,
          {
            headers: {
              Key: `${API_KEY}`,
            },
          }
        );

        const properties = await reponses.json();

        setAllProperties(properties);
      } else {
        const reponses: any[] = await Promise.all([
          fetch(`${BASE_URL}${API_VERSION}/properties/${id}`, {
            headers: {
              Key: `${API_KEY}`,
            },
          }),
          quoted && +groupId !== 1
            ? fetch(`${BASE_URL}${API_VERSION}/bookings/allbookings`, {
                headers: {
                  Quotesonly: "true",
                  "Content-Type": "application/json",
                  Userid: `${id}`,
                  Key: `${API_KEY}`,
                  // IsToday: `1`,
                },
              })
            : fetch(`${BASE_URL}${API_VERSION}/bookings/allbookings`, {
                headers: {
                  Quotesonly: "false",
                  "Content-Type": "application/json",
                  Userid: `${id}`,
                  Key: `${API_KEY}`,
                  // IsToday: `1`,
                },
              }),
        ]);

        // storing the data
        const [properties, bookings] = [
          await reponses[0].json(),
          await reponses[1].json(),
        ];

        // filter the bookings
        const propertiesIDs = properties.map((d: any) => d.id);
        let userBookings: any[] = [];

        for (const d of bookings) {
          if (propertiesIDs.includes(d.property_id)) userBookings.push(d);
        }

        // if owner then only gives confirmed bookings
        if (+groupId === 1) {
          // push bookings to the global state
          const ownersBookings = userBookings.filter(
            (d: any) => d.status === 0
          );

          setContextData(formatData(ownersBookings));
        } else {
          // push bookings to the global state
          setContextData(formatData(bookings));
        }

        // push properties to the global state
        setAllProperties(properties);
      }
    } catch (error) {
      //set the error
      setApiFail(
        `Failed to get data, please check your network or refresh the window and try again. If error persists please contact your developer`
      );
      //display error on console
      throw new Error(`There was an error while fetching data: ${error}`);
    } finally {
      if (activateLoading) setLoading(false);
    }
  };

  // Post to the API
  function postData(id: number, send_data: any) {
    setLoading(true);

    fetch(`${BASE_URL}${API_VERSION}/bookings/${id}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Key: `${API_KEY}`,
      },
      body: JSON.stringify(send_data),
    })
      .then((response) => response.json())
      .then((data) => {
        //get the index of updated record
        const index = contextData.findIndex((d: any) => d.id === data.id);

        //create a new array with the updated information
        const updated = contextData.map((obj: any, i: number) =>
          i === index ? data : obj
        );

        //set the updated information so it can display on the table
        setContextData(formatData(updated));
      })
      .catch((err) => {
        //set the error
        setApiFail(
          `Failed to save data, please check your network or refresh the window and try again. If error persists please contact your developer`
        );
        //display error on console
        console.log(`There was an error while fetching data: ${err}`);
      })
      .finally(() => {
        // stop loading
        setLoading(false);
      });
  }

  //get user's details
  async function getUsers(id: number) {
    try {
      const response = await fetch(`${BASE_URL}${API_VERSION}/users/${id}`, {
        headers: {
          Key: `${API_KEY}`,
        },
      });

      const data = await response.json();

      setUserDetails(data);
    } catch (error) {
      throw new Error(`Couldn't fetch user's details, ${error}`);
    }
  }

  // get all users
  async function fetchUsers() {
    const headers = {
      Key: `${API_KEY}`,
      "Content-Type": "application/json",
    };

    const resp = await fetch(`${BASE_URL}${API_VERSION}/users`, {
      headers,
    });

    const users = await resp.json();

    setPropertyManagers(users);
  }

  return (
    <BookingsContext.Provider
      value={{
        setContextData,
        contextData,
        postData,
        getData,
        getUsers,
        userDetails,
        apiFail,
        setApiFail,
        loading,
        BASE_URL,
        API_KEY,
        WATI_API_KEY,
        WATI_BASE_URL,
        API_VERSION,
        allProperties,
        setAllProperties,
        setLoading,
        handleSetLoggedInUser, //Benito
        loggedInUser, //Benito
        selectedProperty, //Benito
        setSelectedProperty, //Benito
        dynamicModalData,
        setDynamicModalData,
        formatData,
        propertyManagers,
        fetchUsers,
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
}
